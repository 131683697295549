import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import HomeContent from "../components/homecontent/homecontent"
import landingSVG from "../images/landing-img.svg"
const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />

    <section id="home">
      <div id="alert">
        <a className="alert" href="#alert">
          <div className="alert-text">
            During COVID-19 pandemic we are making sure to prioritize the health
            of all our customers as well as the health of ourselves. Call or
            email to find out more about how we can arrange a free quote and
            trim your hedge while respecting social distancing.{" "}
          </div>
        </a>
      </div>
      <div className="landing-content">
        <h1>
          <span className="green">Hedge Cutters</span>
          &nbsp;Ottawa
        </h1>
        <h2>Professional hedge trimming</h2>
        <h2 className="sub-heading">Serving the National Capital Region</h2>
        <div className="notifiaction">
          &nbsp;
          <i className="fas fa-check-circle" /> &nbsp;we're back for spring &
          summer {new Date().getFullYear()} &nbsp;
        </div>
        <div className="landing-services">
          <ul>
            <li>
              <i className="fas fa-leaf fa-fw" />
              Cedar and misc. hedges
            </li>
            <li>
              <i className="fas fa-home fa-fw" />
              Family owned and operated
            </li>
            <li>
              <i className="fas fa-receipt fa-fw" /> Easy payments and invoicing
            </li>
          </ul>
        </div>
        <Link className="btn" to="/contact">
          get a free quote
        </Link>
      </div>
      <div className="landing-img">
        {/* <Image filename="landing-img.svg" alt="houses with hedges" /> */}
        <img src={landingSVG} alt="houses with hedges" />
      </div>
    </section>
    <HomeContent />
  </Layout>
)

export default IndexPage
