import React from "react"

import Image from "../image"

const HomeContent = () => (
  <div>
    <section id="about">
      <div className="container">
        <div className="subcontainer">
          <div className="description">
            <h2>
              <i className="fab fa-pagelines" /> &nbsp;Locally Owned and
              Operated
            </h2>
            <div className="info">
              <div className="vertical-line" />
              <p>
                We provide professional hedge trimming and tree pruning services
                to residents and businesses in the greater Ottawa area.
              </p>
            </div>
          </div>

          <div className="img-container">
            <Image filename="hedges/hedge3.jpeg" />
          </div>
        </div>
      </div>

      {/* <Testimonial
        quote="this worksssss"
        client=" 
    Sue F from Barhaven"
      /> */}
    </section>
    <section id="services">
      <div className="container">
        <div className="subcontainer">
          <div className="img-container second-img">
            <Image filename="hedges/Hedge.jpg" />
          </div>
          <div className="description">
            <h2>
              <i className="fab fa-pagelines" /> &nbsp;Clean, Fast and
              Convenient
            </h2>
            <div className="info">
              <div className="vertical-line" />
              <p>
                We ensure that any trimmings left from our work are taken care
                of. Cleanliness is very important to us.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="pricing">
      <div className="container">
        <div className="subcontainer">
          <div className="description">
            <h2>
              <i className="fab fa-pagelines" /> &nbsp;High quality, low price
            </h2>
            <div className="info">
              <div className="vertical-line" />
              <p>
                We guarantee low prices and every hedge is treated with utmost
                expertise and care by our trained professional hedge trimmers.
              </p>
            </div>
          </div>

          <div className="img-container">
            <Image filename="hedges/hedge5.jpg" />
          </div>
        </div>
      </div>
    </section>
  </div>
)
export default HomeContent
